import clsx from "clsx";
import { Dropdown, Toast } from "bootstrap";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "contexts/GlobalContext";
import { ToastModel } from "types/ToastModel";
import { useHistory } from "react-router-dom";

type ToastItemProps = {
    toast: ToastModel;
};

export const ToastItem = (props: ToastItemProps) => {
    const { toast } = props;
    const { id, type, title, description, hideIcon } = toast;

    const history = useHistory();

    const toastRef = useRef<HTMLDivElement>(null);

    // trigger toast on notification change
    useEffect(() => {
        if (toastRef.current) {
            const options: Partial<Toast.Options> = {};

            options.delay = 7000;

            Toast.getOrCreateInstance(toastRef.current, options)?.show();
        }
    }, []);

    // hide toast on page change
    useEffect(() => {
        history.listen(() => {
            if (toastRef.current) {
                Toast.getOrCreateInstance(toastRef.current)?.hide();
            }
        });
    }, [history]);

    return (
        <div
            key={id}
            ref={toastRef}
            className={clsx(
                "toast",
                type === "success" && "bg-success border-success",
                type === "failure" && "bg-danger border-danger",
            )}
        >
            <div
                className={clsx(
                    "toast-header text-white h6",
                    description && "border-white",
                    type === "success" && "bg-success",
                    type === "failure" && "bg-danger",
                    type === "info" && ["bg-dark", !description && "border-0"],
                )}
            >
                <div className="d-flex gap-2 align-items-center">
                    {!hideIcon && (
                        <Fragment>
                            {type === "success" && <span className="fas fa-check"></span>}
                            {type === "failure" && <span className="fas fa-triangle-exclamation"></span>}
                        </Fragment>
                    )}
                    <span>{title}</span>
                </div>

                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>

            {description && (
                <div
                    className={clsx(
                        "toast-body vstack gap-3",
                        type === "success" && "bg-success",
                        type === "failure" && "bg-danger",
                        type === "info" && "bg-dark",
                    )}
                >
                    {description && <div>{description}</div>}
                </div>
            )}
        </div>
    );
};

const ToastHistory = () => {
    const { notifications } = useContext(GlobalContext);

    const [dropdownNotifications, setDropdownNotifications] = useState<Array<ToastModel>>([]);

    useEffect(() => {
        setDropdownNotifications([...notifications.slice(0).reverse()]);
    }, [notifications]);

    // close menu on page change
    const btnRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (btnRef.current) {
            if (dropdownNotifications.length === 0) {
                Dropdown.getOrCreateInstance(btnRef.current).hide();
            }
        }
    }, [dropdownNotifications.length]);

    return (
        <Fragment>
            <button
                type="button"
                ref={btnRef}
                className={clsx(
                    "btn btn-notification rounded-pill animate",
                    dropdownNotifications.length > 0 ? "opacity-100" : "opacity-0",
                )}
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                style={{ pointerEvents: "all" }}
                onClick={() => {
                    const toastItems = document.querySelectorAll(".toast");

                    for (const t of toastItems) {
                        const toast = Toast.getOrCreateInstance(t);

                        if (toast) {
                            toast.hide();
                        }
                    }
                }}
            >
                <span className="fas fa-info"></span>
            </button>

            <div className="dropdown-menu dropdown-menu-lg-end" style={{ pointerEvents: "all" }}>
                <div
                    className="d-flex gap-3 flex-column p-3"
                    style={{
                        maxHeight: "80vh",
                        overflowY: "auto",
                        overflowX: "hidden",
                        minWidth: 350,
                    }}
                >
                    {dropdownNotifications.map(n => {
                        const { id, type, hideIcon, description, title } = n;

                        return (
                            <div
                                key={id}
                                className={clsx(
                                    "p-3 vstack gap-2",
                                    type === "success" && "bg-success border-success",
                                    type === "failure" && "bg-danger border-danger",
                                    type === "info" && ["bg-dark", !description && "border-0"],
                                )}
                            >
                                <div className={clsx("text-white h6 text-nowrap", description && "border-white")}>
                                    <div className="d-flex gap-2 align-items-center fw-bolder">
                                        {!hideIcon && (
                                            <Fragment>
                                                {type === "success" && <span className="fas fa-check"></span>}
                                                {type === "failure" && (
                                                    <span className="fas fa-triangle-exclamation"></span>
                                                )}
                                            </Fragment>
                                        )}
                                        <span>{title}</span>
                                    </div>
                                </div>

                                {description && <div>{description}</div>}
                            </div>
                        );
                    })}
                </div>
            </div>
        </Fragment>
    );
};

export const ToastContainer = () => {
    const { notifications } = useContext(GlobalContext);

    return (
        <div className="toast-container">
            <ToastHistory />

            {notifications.map(t => (
                <ToastItem toast={t} key={t.id} />
            ))}
        </div>
    );
};
