import TagManager, { TagManagerArgs } from "react-gtm-module";
import { Guid } from "guid-typescript";
import { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import { ToastModel } from "types/ToastModel";
import { ServerErrorResponse } from "results/CommandResult";
import { WindowConfirmProps } from "components/WindowConfirm";
import { Constants } from "utils/Constants";
import { LoginResult } from "results/account/LoginResult";
import { useHistory } from "react-router-dom";
import { RouteList } from "Routes";
import { buildDataLayer, dataTokenKey, getUserData } from "utils/GtmUtils";
import { PromptTag } from "components/prompts/PromptSearch";
import { PresetPromptDto } from "dtos/frankenstories/prompts/PresetPromptDto";
import { GlobalDataResult } from "results/account/GlobalDataResult";
import { PresetsApiService } from "services/PresetsApiService";
import { SchoolsApiService } from "services/SchoolsApiService";
import { TeachersApiService } from "services/TeachersApiService";
import { PaymentsApiService } from "services/PaymentsApiService";
import { UsersApiService } from "services/UsersApiService";
import { GamesApiService } from "services/GamesApiService";
import { ContentApiService } from "services/ContentApiService";
import { AccountApiService } from "services/AccountApiService";
import { ReportingApiService } from "services/ReportingApiService";
import { ModerateApiService } from "services/ModerateApiService";
import { GameplayApiService } from "services/GameplayApiService";

// initialise sound
const audioInitialisedLocalStorageKey = "a-init";

// default init off
sessionStorage.removeItem(audioInitialisedLocalStorageKey);

const setIsAudioInitialised = (initialised: boolean) => {
    if (initialised) {
        sessionStorage.setItem(audioInitialisedLocalStorageKey, "true");
    } else {
        sessionStorage.removeItem(audioInitialisedLocalStorageKey);
    }
};

export const getIsAudioInitialised = () => !!sessionStorage.getItem(audioInitialisedLocalStorageKey);

// api service
const presetsService = PresetsApiService();
const schoolsService = SchoolsApiService();
const teachersService = TeachersApiService();
const paymentsService = PaymentsApiService();
const usersService = UsersApiService();
const gamesService = GamesApiService();
const contentService = ContentApiService();
const accountService = AccountApiService();
const reportingService = ReportingApiService();
const moderateService = ModerateApiService();
const gameplayService = GameplayApiService();

// auth / session id
const storage = localStorage.getItem("Shitashi") ? sessionStorage : localStorage;

// get username from session
const sessionIdKey = "sessionId";
const sessionIdValue = storage.getItem(sessionIdKey);
export const sessionId = sessionIdValue ?? Guid.create().toString();

export const authTokenKey = "token";

// these are temp fixes to clear out any old user data
localStorage.removeItem("auth-token");
localStorage.removeItem("data-key");

// get token
export const getToken = () => storage.getItem(authTokenKey) ?? "";
export const accessTokenFactory = () => getToken();

// save session id for later just in case
storage.setItem(sessionIdKey, sessionId.toString());

// initialise gtm
const tagManagerArgs: TagManagerArgs = {
    gtmId: Constants.gtm,
};

TagManager.initialize(tagManagerArgs);

// initial data layer push
const dataLayer = buildDataLayer();

TagManager.dataLayer({
    dataLayer,
});

export const useGlobalContext = () => {
    const history = useHistory();

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
    const [authToken, setAuthToken] = useState<string>();
    const [error, setError] = useState<string>();
    const [notifications, setNotifications] = useState<Array<ToastModel>>([]);
    const [windowConfirm, setWindowConfirm] = useState<WindowConfirmProps>();
    const [userData, setUserData] = useState(getUserData());
    const [ip, setIp] = useState<string>();
    const [globalData, setGlobalData] = useState<GlobalDataResult>();

    const clearNotifications = () => setNotifications([]);

    const addNotification = useCallback((toast: ToastModel) => {
        setNotifications(old => [...old, { ...toast, id: old.length }]);
    }, []);

    // globally handle server errors
    const catchServerError = useCallback((e: unknown) => {
        // eslint-disable-next-line no-console
        console.log("Error:");
        // eslint-disable-next-line no-console
        console.log(e);
        setError("An error occurred");
    }, []);

    // update fs auth
    useEffect(() => {
        // the user is considered logged in if there's a token
        const token = storage.getItem(authTokenKey) ?? "";

        setIsAuthenticated(!!token);
    }, []);

    const updateAuth = useCallback((data: LoginResult) => {
        // save token
        setAuthToken(data.token);

        // set auth details
        setIsAuthenticated(!!data.token);

        // save
        localStorage.setItem(authTokenKey, data.token);
        localStorage.setItem(dataTokenKey, JSON.stringify(data));
    }, []);

    const catchFormError = useCallback(
        (response: ServerErrorResponse) => {
            // in the case of a 404 the game has been deleted or closed
            if (response.status === 404) {
                setError("Could not find game");
            }

            if (response.errors) {
                for (const prop in response.errors) {
                    const error = response.errors[prop];

                    addNotification({
                        title: "Oh no!",
                        type: "failure",
                        description: error,
                    });
                }
            }
        },
        [addNotification],
    );

    const logout = useCallback(() => {
        setAuthToken("");
        setIsAuthenticated(false);
        setUserData(undefined);

        storage.removeItem(authTokenKey);
        storage.removeItem(dataTokenKey);

        history.push(RouteList.Home);
    }, [history]);

    // track sound first click for safari
    const unlockAudio = useCallback(() => {
        // unbind listeners
        document.body.removeEventListener("click", unlockAudio);
        document.body.removeEventListener("touchstart", unlockAudio);

        setIsAudioInitialised(true);
    }, []);

    useEffect(() => {
        // listen for initial interaction to enable sound
        document.body.addEventListener("click", unlockAudio);
        document.body.addEventListener("touchstart", unlockAudio);
    }, [unlockAudio]);

    // get global user data on initial page load
    useEffect(() => {
        if (isAuthenticated !== undefined) {
            accountService
                .getGlobalUserData()
                .then(x => {
                    // log user out if their account does exist
                    if (isAuthenticated && !x.userExists) {
                        logout();

                        return;
                    }

                    // gtm
                    if (isAuthenticated) {
                        TagManager.dataLayer({
                            dataLayer: {
                                userType: x.userType,
                                accountType: x.accountType,
                            },
                        });
                    }

                    // ip
                    setIp(x.ip);

                    setGlobalData(x);
                })
                .catch(catchServerError);
        }
    }, [catchServerError, isAuthenticated, logout]);

    // global prompt search result
    const [promptSearchResults, setPromptSearchResults] = useState<Array<PresetPromptDto>>();

    const getPromptSearchResults = useCallback(async () => {
        // return saved data if it already exists
        if (promptSearchResults) {
            return promptSearchResults;
        }

        const x = await presetsService.getPresetSearch();

        const { prompts } = x;

        setPromptSearchResults(prompts);

        return prompts;
    }, [promptSearchResults]);

    // global prompt tags
    const [promptSearchTags, setPromptSearchTags] = useState<Array<PromptTag>>();

    const getPromptSearchTags = useCallback(async () => {
        // return saved data if it already exists
        if (promptSearchTags) {
            return promptSearchTags;
        }

        const x = await presetsService.getPresetsTags();

        const newData: Array<PromptTag> = [];

        let id = 1;

        for (const tag of x.tags) {
            newData.push({
                id,
                name: tag.name,
                tagId: tag.id,
                tagType: "tag",
            });

            id++;
        }

        for (const tag of x.programs) {
            newData.push({
                id,
                name: tag.name,
                tagId: tag.id,
                tagType: "program",
            });

            id++;
        }

        for (const tag of x.topics) {
            newData.push({
                id,
                name: tag.name,
                tagId: tag.id,
                tagType: "topic",
            });

            id++;
        }

        setPromptSearchTags(newData);

        return newData;
    }, [promptSearchTags]);

    return {
        error,
        setError,
        presetsService,
        schoolsService,
        teachersService,
        paymentsService,
        usersService,
        gamesService,
        contentService,
        accountService,
        moderateService,
        reportingService,
        gameplayService,
        notifications,
        addNotification,
        catchServerError,
        catchFormError,
        sessionId,
        windowConfirm,
        setWindowConfirm,
        updateAuth,
        logout,
        authToken,
        isAuthenticated,
        clearNotifications,
        userData,
        globalData,
        setGlobalData,
        getPromptSearchTags,
        getPromptSearchResults,
        ip,
    };
};

// automatic exports
export type IGlobalContext = ReturnType<typeof useGlobalContext>;
export const GlobalContext = createContext<IGlobalContext>({} as IGlobalContext);

export const GlobalContextProvider = (props: { children: ReactNode }) => {
    const globalContext = useGlobalContext();
    return <GlobalContext.Provider value={globalContext}>{props.children}</GlobalContext.Provider>;
};
