import { SubmitContactFormCommand } from "commands/contact-submissions/SubmitContactFormCommand";
import { NewsletterRegisterCommand } from "commands/NewsletterRegisterCommand";
import { GetPageResult } from "results/content/GetPageResult";
import { GetTeamMembersResult } from "results/content/GetTeamMembersResult";
import { GetHomeResult } from "results/frankenstories/GetHomeResult";
import { SuccessResult } from "results/SuccessResult";
import { useApiService } from "services/ApiService";

export const ContentApiService = () => {
    const { get, post } = useApiService({ controller: "/content" });

    return {
        getHome: () => get<GetHomeResult>("/home"),
        getTerms: () => get<GetPageResult>("/terms"),
        getPrivacy: () => get<GetPageResult>("/privacy"),
        getEvents: () => get<GetPageResult>("/events"),
        getAbout: () => get<GetPageResult>("/about"),
        getSafety: () => get<GetPageResult>("/safety"),
        getPermissions: () => get<GetPageResult>("/permissions"),
        getCompetitionTerms: () => get<GetPageResult>("/competition-terms"),
        getTeam: () => get<GetTeamMembersResult>("/team"),
        submitContact: (command: SubmitContactFormCommand) => post<SuccessResult>("/submit-contact", command),
        registerNewsletter: (command: NewsletterRegisterCommand) =>
            post<SuccessResult>("/newsletter-register", command),
    };
};
