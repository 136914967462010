import Icon from "assets/img/icon.svg";
import { RouteList } from "Routes";
import { Link, NavLink, matchPath } from "react-router-dom";
import { Fragment, useContext } from "react";
import { GlobalContext } from "contexts/GlobalContext";
import { ReactComponent as SiteName } from "assets/img/site-name.svg";
import { LogoutButton } from "components/buttons/LogoutButton";
import clsx from "clsx";
import { Constants } from "utils/Constants";

export const Navbar = () => {
    const { isAuthenticated, userData } = useContext(GlobalContext);

    const isInProfile = matchPath(location.pathname, RouteList.Profile);

    return (
        <nav className="navbar navbar-dark bg-dark navbar-expand-lg sticky-top">
            <div className="container flex-row">
                <NavLink className="d-flex align-items-center" to={RouteList.Home} exact={true}>
                    <img alt="Frankenstories" className="me-2" src={Icon} style={{ height: 26, maxWidth: "100%" }} />

                    <SiteName style={{ height: 16, width: 125, maxWidth: "100%" }} className="me-2 svg-link" />
                </NavLink>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbar">
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link" to={RouteList.Games}>
                                <span>🧟</span>
                                <span className="nav-link__text">Games</span>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to={RouteList.Prompts}>
                                <span>📖</span>
                                <span className="nav-link__text">Prompts</span>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to={RouteList.Help} className="nav-link">
                                <span>✋</span>
                                <span className="nav-link__text">Help</span>
                            </NavLink>
                        </li>

                        {!userData?.isStudent && (
                            <li className="nav-item">
                                <NavLink className="nav-link" to={RouteList.Subscriptions}>
                                    <span>🚀</span>
                                    <span className="nav-link__text">Subscriptions</span>
                                </NavLink>
                            </li>
                        )}
                    </ul>

                    <ul className="navbar-nav">
                        {isAuthenticated ? (
                            <Fragment>
                                {!userData?.isStudent && (
                                    <li>
                                        <NavLink className="nav-link" to={RouteList.Teachers}>
                                            <span>👨‍🏫</span>
                                            <span className="nav-link__text">Class Admin</span>
                                        </NavLink>
                                    </li>
                                )}

                                <li className="nav-item dropdown" id="nav-profile">
                                    <button
                                        type="button"
                                        className={clsx("nav-link dropdown-toggle", isInProfile && "active")}
                                        data-bs-toggle="dropdown"
                                    >
                                        <span>🙋</span>
                                        <span className="nav-link__text">Profile</span>
                                    </button>

                                    <ul className="dropdown-menu dropdown-menu-end w-100 w-lg-auto">
                                        <li>
                                            <Link className="dropdown-item" to={RouteList.ProfileMyClasses}>
                                                <span>👪</span>
                                                <span className="nav-link__text">My classes</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to={RouteList.ProfileMyAvatars}>
                                                <span>👺</span>
                                                <span className="nav-link__text">My avatars</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to={RouteList.Profile}>
                                                <span>🙋</span>
                                                <span className="nav-link__text">User details</span>
                                            </Link>
                                        </li>
                                        {!userData?.isStudent && (
                                            <Fragment>
                                                <li>
                                                    <Link className="dropdown-item" to={RouteList.ProfileMySchool}>
                                                        <span>🏫</span>
                                                        <span className="nav-link__text">My school</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to={RouteList.ProfileReferAFriend}>
                                                        <span>👋</span>
                                                        <span className="nav-link__text">Refer a friend</span>
                                                    </Link>
                                                </li>
                                            </Fragment>
                                        )}
                                        <li>
                                            <Link className="dropdown-item" to={RouteList.ChangePassword}>
                                                <span>🔑</span>
                                                <span className="nav-link__text">Change password</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <LogoutButton className="dropdown-item" />
                                        </li>
                                    </ul>
                                </li>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <li className="nav-item dropdown" id="nav-register">
                                    <button
                                        type="button"
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                    >
                                        <span>✅</span>
                                        <span className="nav-link__text">Register</span>
                                    </button>

                                    <ul className="dropdown-menu dropdown-menu-end w-100 w-lg-auto">
                                        <li>
                                            <Link to={RouteList.RegisterTeacher} className="dropdown-item">
                                                <span>👨‍🏫</span>
                                                <span className="nav-link__text">Teacher</span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to={RouteList.RegisterStudent} className="dropdown-item">
                                                <span>🙋</span>
                                                <span className="nav-link__text">Student</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item dropdown" id="nav-login">
                                    <button
                                        type="button"
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                    >
                                        <span>🔑</span>
                                        <span className="nav-link__text">Log in</span>
                                    </button>

                                    <ul className="dropdown-menu dropdown-menu-end w-100 w-lg-auto">
                                        <li>
                                            <Link to={RouteList.Login} className="dropdown-item">
                                                <span>👨‍🏫</span>
                                                <span className="nav-link__text">Teacher</span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to={RouteList.StudentLogin} className="dropdown-item">
                                                <span>🙋</span>
                                                <span className="nav-link__text">Student</span>
                                            </Link>
                                        </li>

                                        <li>
                                            <a target="_blank" href={Constants.schoolsUrl} className="dropdown-item">
                                                <span>🏫</span>
                                                <span className="nav-link__text">School</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </Fragment>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};
